<template>
  <main
    class="color-primary h-100 blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <TourismReport :reportDetails="transactionData" :loading="loading"></TourismReport>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TourismReport from "./../../components/tourism/TourismReport.vue"

export default {
  name: "TourismOrderDetail",
  components: {
    TourismReport
  },
  data() {
    return {
      timer: null
    }
  },
  computed: {
    ...mapState({
      loading: state => state.tour_order.loading,
      transactionData: state => state.tour_order.transactionData,
    }),
    orderId() {
      return this.$route.params.orderId;
    },

  },
  async created() {
    await this.getTransactionData({ id: this.orderId, phoneNumber: "" });
    if (this.transactionData.status == 0) {
      this.startInterval();
    }
  },
  methods: {
    ...mapActions("tour_order", {
      getTransactionData: "getTransactionData",
    }),
    startInterval() {
      const that =  this;
      this.timer = setInterval(() => {
        that.getTransactionData({ id: that.orderId, phoneNumber: "" }).then(()=>{
          if (that.transactionData.status) {
            clearInterval(that.timer);
          }
        })
      }, 60000);
    }
  },
  beforeRouteLeave (to, from, next) {
    if(this.timer)
      clearInterval(this.timer);
    next();
  },
  beforeDestory() {
    if(this.timer)
      clearInterval(this.timer);
  }

}
</script>